body {
  /* height: 100%;
  margin: 0;
  padding: 0; */
  font-family: "Helvetica", "Roboto", "Arial", "sans-serif";
  /* -webkit-font-smoothing: antialiased; */
  /* -moz-osx-font-smoothing: grayscale; */
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

/* hide google map's logo */
a[href^="https://maps.google.com/maps"] {display:none !important}