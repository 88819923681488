.video_container {
  position: relative
}

.video_container video::-webkit-media-controls {
  display: none !important;
}

.video_container:-webkit-full-screen {
  width: 100%;
}

.video_controls {
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 48px;
  opacity: 0.9;
  z-index: 1;
  background-image: linear-gradient(to top, rgb(0, 0, 0), rgba(0, 0, 0, 0));
}

.video_container_full_controls:-webkit-full-screen {
  width: 100%;
}