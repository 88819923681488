.WebRTCViewer_video_container__3RzFQ {
  position: relative
}

.WebRTCViewer_video_container__3RzFQ video::-webkit-media-controls {
  display: none !important;
}

.WebRTCViewer_video_container__3RzFQ:-webkit-full-screen {
  width: 100%;
}

.WebRTCViewer_video_controls__3mTdG {
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 48px;
  opacity: 0.9;
  z-index: 1;
  background-image: linear-gradient(to top, rgb(0, 0, 0), rgba(0, 0, 0, 0));
}

.WebRTCViewer_video_container_full_controls__2V7cp:-webkit-full-screen {
  width: 100%;
}
.HlsPlayer_video_container__lfCbo {
  position: relative
}

.HlsPlayer_video_container__lfCbo video::-webkit-media-controls {
  display: none !important;
}

.HlsPlayer_video_container__lfCbo:-webkit-full-screen {
  width: 100%;
}

.HlsPlayer_video_controls__3dGUo {
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 48px;
  opacity: 0.9;
  z-index: 1;
  background-image: linear-gradient(to top, rgb(0, 0, 0), rgba(0, 0, 0, 0));
}

.HlsPlayer_video_container_full_controls__30IKv:-webkit-full-screen {
  width: 100%;
}
.EventPlayer_video_container__1Z-R- {
    position: relative
}

.EventPlayer_video_container__1Z-R- video::-webkit-media-controls {
    display: none !important;
}

.EventPlayer_video_container__1Z-R-:-webkit-full-screen {
    width: 100%;
}

.EventPlayer_video_controls__2FvOR {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 72px;
    opacity: 0.9;
    z-index: 1;
    background-image: linear-gradient(to top, rgb(0, 0, 0), rgba(0, 0, 0, 0));
}
body {
  /* height: 100%;
  margin: 0;
  padding: 0; */
  font-family: "Helvetica", "Roboto", "Arial", "sans-serif";
  /* -webkit-font-smoothing: antialiased; */
  /* -moz-osx-font-smoothing: grayscale; */
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

/* hide google map's logo */
a[href^="https://maps.google.com/maps"] {display:none !important}
